import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { formatQuery } from "../Utils";
import { MasterScheduleActionTypes } from "./MasterSchedule.type";

export function* fetchAllMasterSchedules(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/master-schedule?${formatQuery(action)}`
    );
    yield put({
      type: MasterScheduleActionTypes.FETCH_ALL_MASTER_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MasterScheduleActionTypes.FETCH_ALL_MASTER_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneMasterSchedules(action: any): any {
  try {
    const response = yield axios.get(
      `${API_BASE_URI}/master-schedule/${action.payload}`
    );
    yield put({
      type: MasterScheduleActionTypes.FETCH_ONE_MASTER_SCHEDULE_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: MasterScheduleActionTypes.FETCH_ONE_MASTER_SCHEDULE_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllMasterSchedules() {
  yield takeLatest(
    MasterScheduleActionTypes.FETCH_ALL_MASTER_SCHEDULE,
    fetchAllMasterSchedules
  );
}

export function* watcherFetchOneMasterSchedules() {
  yield takeLatest(
    MasterScheduleActionTypes.FETCH_ONE_MASTER_SCHEDULE,
    fetchOneMasterSchedules
  );
}
