import { ApiCallState, PagedData } from "../Utils";

export type TeamMember = {
  id: number;
  user_id: number;
  teamMember_id: number;

  type: string;

  readonly createdAt: Date;
  readonly updatedAt: Date;
};

export type TeamMemberStateTypes = {
  fetchAll: ApiCallState<TeamMember[]>;
  fetchOne: ApiCallState<TeamMember | {}>;
  fetchPaged: ApiCallState<PagedData<TeamMember[]>>;
};

export const TeamMemberActionTypes = {
  FETCH_ALL_TEAM_MEMBER: "FETCH_ALL_TEAM_MEMBER",
  FETCH_ALL_TEAM_MEMBER_RESET: "FETCH_ALL_TEAM_MEMBER_RESET",
  FETCH_ALL_TEAM_MEMBER_FAILURE: "FETCH_ALL_TEAM_MEMBER_FAILURE",
  FETCH_ALL_TEAM_MEMBER_SUCCESS: "FETCH_ALL_TEAM_MEMBER_SUCCESS",

  FETCH_PAGED_TEAM_MEMBER: "FETCH_PAGED_TEAM_MEMBER",
  FETCH_PAGED_TEAM_MEMBER_RESET: "FETCH_PAGED_TEAM_MEMBER_RESET",
  FETCH_PAGED_TEAM_MEMBER_FAILURE: "FETCH_PAGED_TEAM_MEMBER_FAILURE",
  FETCH_PAGED_TEAM_MEMBER_SUCCESS: "FETCH_PAGED_TEAM_MEMBER_SUCCESS",

  FETCH_ONE_TEAM_MEMBER: "FETCH_ONE_TEAM_MEMBER",
  FETCH_ONE_TEAM_MEMBER_RESET: "FETCH_ONE_TEAM_MEMBER_RESET",
  FETCH_ONE_TEAM_MEMBER_FAILURE: "FETCH_ONE_TEAM_MEMBER_FAILURE",
  FETCH_ONE_TEAM_MEMBER_SUCCESS: "FETCH_ONE_TEAM_MEMBER_SUCCESS",
};
