import { ApiCallState } from "../Utils";
import { ContractPayment } from "../ContractPayment/ContractPayment.type";
import { ApartmentUnit } from "../ApartmentBuilding/ApartmentUnit/ApartmentUnit.type";

export type ContractAgreement = {
  id: number;
  date: string;
  apartment_id: number;
  createdAt: string;
  buyers: {
    name: string;
    city: string;
    sub_city: string;
    house_no: string;
    id: string;
    woreda: string;
    kebele: string;
    region: string;
    country: string;
    phone: string;
    email: string;
  }[];

  square_space: number;
  floor: number;
  type: string;
  price: number;
  payment: { description: string; key: number; percentage: number }[];
  contract_payments?: ContractPayment[];
  witness: {
    name: string;
    city: string;
    sub_city: string;
    woreda: string;
    house_no: string;
    kebele: string;
  }[];
  total_square_space: number;
  user_id: number;
  apartment_unit?: ApartmentUnit;
};

export type ContractAgreementStateTypes = {
  fetchAll: ApiCallState<ContractAgreement[]>;
  fetchOne: ApiCallState<ContractAgreement | {}>;
};

export const ContractAgreementActionTypes = {
  FETCH_ALL_CONTRACT_AGREEMENT: "FETCH_ALL_CONTRACT_AGREEMENT",
  FETCH_ALL_CONTRACT_AGREEMENT_RESET: "FETCH_ALL_CONTRACT_AGREEMENT_RESET",
  FETCH_ALL_CONTRACT_AGREEMENT_FAILURE: "FETCH_ALL_CONTRACT_AGREEMENT_FAILURE",
  FETCH_ALL_CONTRACT_AGREEMENT_SUCCESS: "FETCH_ALL_CONTRACT_AGREEMENT_SUCCESS",

  FETCH_ONE_CONTRACT_AGREEMENT: "FETCH_ONE_CONTRACT_AGREEMENT",
  FETCH_ONE_CONTRACT_AGREEMENT_RESET: "FETCH_ONE_CONTRACT_AGREEMENT_RESET",
  FETCH_ONE_CONTRACT_AGREEMENT_FAILURE: "FETCH_ONE_CONTRACT_AGREEMENT_FAILURE",
  FETCH_ONE_CONTRACT_AGREEMENT_SUCCESS: "FETCH_ONE_CONTRACT_AGREEMENT_SUCCESS",
};
