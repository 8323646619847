import { Department } from "../Department/Department.type";
import { Document } from "../Document/Document.type";
import { Material } from "../Material/Material.type";
import { Project } from "../Project/Project.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type MaterialRequisition = {
  mr_number: number;
  requested_to: string;
  department_id?: number;
  project_id?: number;
  project?: Project;
  department?: Department;
  date: string;
  id: any;
  remark: string;
  is_planned: boolean;
  type: string;
  material_requisition_items: MaterialRequisitionItem[];
  material_requisition_documents: MaterialRequisitionDocument[];
  requested_by: string;
  mr_prepared_by: User | null;
  mr_checked_by: User | null;
  mr_authorized_by: User | null;
  checked_date: string;
  approved_date: string;
  is_done: boolean;
  approver_comment: string;
  authorized_by: number;
  checked_by: number;
  is_approved: boolean;
  is_checked: boolean;
  user_id: number;
};

export type MaterialRequisitionItem = {
  material: Material;
  quantity: number;
  additional_quantity: number;
  received: number;
  comment: string;
  material_id: number;
  id: number;
};

export type MaterialRequisitionDocument = {
  id: number;
  material_requisition_id: number;
  document_id: number;
  description: string;
  document: Document;
};

export type MaterialRequisitionItemStatus = {
  mri_id: number;
  status: string;
};

export type MaterialRequisitionStateTypes = {
  fetchAll: ApiCallState<MaterialRequisition[]>;
  fetchOne: ApiCallState<MaterialRequisition | {}>;
  fetchStatus: ApiCallState<MaterialRequisitionItemStatus[] | []>;
};

export const MaterialRequisitionActionTypes = {
  FETCH_ALL_MATERIAL_REQUISITION: "FETCH_ALL_MATERIAL_REQUISITION",
  FETCH_ALL_MATERIAL_REQUISITION_RESET: "FETCH_ALL_MATERIAL_REQUISITION_RESET",
  FETCH_ALL_MATERIAL_REQUISITION_FAILURE:
    "FETCH_ALL_MATERIAL_REQUISITION_FAILURE",
  FETCH_ALL_MATERIAL_REQUISITION_SUCCESS:
    "FETCH_ALL_MATERIAL_REQUISITION_SUCCESS",

  FETCH_ONE_MATERIAL_REQUISITION: "FETCH_ONE_MATERIAL_REQUISITION",
  FETCH_ONE_MATERIAL_REQUISITION_RESET: "FETCH_ONE_MATERIAL_REQUISITION_RESET",
  FETCH_ONE_MATERIAL_REQUISITION_FAILURE:
    "FETCH_ONE_MATERIAL_REQUISITION_FAILURE",
  FETCH_ONE_MATERIAL_REQUISITION_SUCCESS:
    "FETCH_ONE_MATERIAL_REQUISITION_SUCCESS",

  FETCH_MATERIAL_REQUISITION_ITEM_STATUS:
    "FETCH_MATERIAL_REQUISITION_ITEM_STATUS",
  FETCH_MATERIAL_REQUISITION_ITEM_STATUS_RESET:
    "FETCH_MATERIAL_REQUISITION_ITEM_STATUS_RESET",
  FETCH_MATERIAL_REQUISITION_ITEM_STATUS_FAILURE:
    "FETCH_MATERIAL_REQUISITION_ITEM_STATUS_FAILURE",
  FETCH_MATERIAL_REQUISITION_ITEM_STATUS_SUCCESS:
    "FETCH_MATERIAL_REQUISITION_ITEM_STATUS_SUCCESS",
};
