import axios from "axios";
import { takeLatest, put } from "redux-saga/effects";

import { API_BASE_URI } from "../ApiCall";
import { LeadActionTypes } from "./Lead.type";

export function* fetchAllLeads(action: any): any {
  try {
    let query = "";
    if (action.payload) {
      const keys = Object.keys(action.payload);
      query = keys
        .map((key) => {
          if (action.payload[key]) {
            return `${key}=${action.payload[key]}`;
          }
        })
        .join("&");
    }
    const response = yield axios.get(`${API_BASE_URI}/lead?${query}`);
    yield put({
      type: LeadActionTypes.FETCH_ALL_LEAD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LeadActionTypes.FETCH_ALL_LEAD_FAILURE,
      payload: error,
    });
  }
}

export function* fetchOneLead(action: any): any {
  try {
    const response = yield axios.get(`${API_BASE_URI}/lead/${action.payload}`);
    yield put({
      type: LeadActionTypes.FETCH_ONE_LEAD_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: LeadActionTypes.FETCH_ONE_LEAD_FAILURE,
      payload: error,
    });
  }
}

export function* watcherFetchAllLeads() {
  yield takeLatest(LeadActionTypes.FETCH_ALL_LEAD, fetchAllLeads);
}

export function* watcherFetchOneLead() {
  yield takeLatest(LeadActionTypes.FETCH_ONE_LEAD, fetchOneLead);
}
