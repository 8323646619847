import { toNumber } from "lodash";
import moment from "moment";
import { MasterSchedule } from "../../redux/MasterSchedule/MasterSchedule.type";

class MasterScheduleExcel {
  data: any[];
  sheet_name: string;
  parsed: MasterSchedule[];
  project_id: number;
  master_schedule_list_id: number;

  constructor(
    data: any,
    sheet_name: string,
    project_id: number,
    master_schedule_list_id: number
  ) {
    this.data = data;
    this.parsed = [];
    this.sheet_name = sheet_name;
    this.project_id = project_id;
    this.master_schedule_list_id = master_schedule_list_id;
  }

  parse() {
    this.data.forEach((col: any[], index) => {
      if (index > 0)
        this.parsed.push({
          assigned_users: [],
          dependant_on: null,
          dependency_id: null,
          duration: moment(col[2]).diff(moment(col[1]), "d"),
          id: null,
          end_date: moment(col[2]).format("YYYY-MM-DD"),
          start_date: moment(col[1]).format("YYYY-MM-DD"),
          is_done: toNumber(col[3]) >= 100,
          finished_date: null,
          current_quantity: 0,
          key: index,
          is_title: !col[6] && !col[7],
          parent_id: null,
          progress: toNumber(col[3]),
          task_name: col[0],
          outline_level: toNumber(col[5]),
          project_id: this.project_id,
          unit: col[7],
          unit_price: toNumber(col[9]),
          quantity: toNumber(col[8]),
          amount: toNumber(col[10]),
          master_schedule_reports: [],
          user_id: null,
          master_schedule_list_id: this.master_schedule_list_id,
        });
    });
    return this.parsed;
  }
}
export default MasterScheduleExcel;
