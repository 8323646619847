import { Department } from "../Department/Department.type";
import { Material } from "../Material/Material.type";
import { MaterialRequisition } from "../MaterialRequisition/MaterialRequisition.type";
import { Project } from "../Project/Project.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type PurchaseRequisition = {
  material_requisition_id: number;
  requested_by: string;
  requested_to: string;
  date: string;
  department_id?: number;
  project_id?: number;
  project?: Project;
  department?: Department;
  id: number;
  remark: string;
  purchase_place: string;
  material_requisition: MaterialRequisition;
  purchase_requisition_items: PurchaseRequisitionItem[];
  pr_prepared_by?: User;
  purchase_requisition_statuses: PurchaseRequisitionStatus[];
  is_done: boolean;
  status: PurchaseRequisitionStatus[];
};

export type PurchaseRequisitionStatus = {
  purchase_requisition_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type PurchaseRequisitionItem = {
  id: any;
  material_id: number;
  material: Material;
  quantity: number;
  estimate_cost: number;
  required_specification: string;
};

export type PurchaseRequisitionStateTypes = {
  fetchAll: ApiCallState<PurchaseRequisition[]>;
  fetchOne: ApiCallState<PurchaseRequisition | {}>;
  fetchReport: ApiCallState<any[]>;
};

export const PurchaseRequisitionActionTypes = {
  FETCH_ALL_PURCHASE_REQUISITION: "FETCH_ALL_PURCHASE_REQUISITION",
  FETCH_ALL_PURCHASE_REQUISITION_RESET: "FETCH_ALL_PURCHASE_REQUISITION_RESET",
  FETCH_ALL_PURCHASE_REQUISITION_FAILURE:
    "FETCH_ALL_PURCHASE_REQUISITION_FAILURE",
  FETCH_ALL_PURCHASE_REQUISITION_SUCCESS:
    "FETCH_ALL_PURCHASE_REQUISITION_SUCCESS",

  FETCH_ONE_PURCHASE_REQUISITION: "FETCH_ONE_PURCHASE_REQUISITION",
  FETCH_ONE_PURCHASE_REQUISITION_RESET: "FETCH_ONE_PURCHASE_REQUISITION_RESET",
  FETCH_ONE_PURCHASE_REQUISITION_FAILURE:
    "FETCH_ONE_PURCHASE_REQUISITION_FAILURE",
  FETCH_ONE_PURCHASE_REQUISITION_SUCCESS:
    "FETCH_ONE_PURCHASE_REQUISITION_SUCCESS",

  FETCH_REPORT_PURCHASE_REQUISITION: "FETCH_REPORT_PURCHASE_REQUISITION",
  FETCH_REPORT_PURCHASE_REQUISITION_RESET:
    "FETCH_REPORT_PURCHASE_REQUISITION_RESET",
  FETCH_REPORT_PURCHASE_REQUISITION_FAILURE:
    "FETCH_REPORT_PURCHASE_REQUISITION_FAILURE",
  FETCH_REPORT_PURCHASE_REQUISITION_SUCCESS:
    "FETCH_REPORT_PURCHASE_REQUISITION_SUCCESS",
};
