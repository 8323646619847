import { all, call } from "redux-saga/effects";
import {
  watcherFetchAllAccounts,
  watcherFetchOneAccounts,
} from "./Account/Account.saga";
import {
  watcherFetchAllBoqs,
  watcherFetchOneBoqs,
  watcherFetchBoqTotal,
  watcherFetchSheetNames,
} from "./Boq/Boq.saga";
import { watcherFetchAllConsultants } from "./Consultant/Consultant.saga";
import { watcherFetchAllContractors } from "./Contractor/Contractor.saga";
import { watcherFetchAllCustomers } from "./Customer/Customer.saga";
import { watcherFetchAllDepartments } from "./Department/Department.saga";
import { watcherFetchAllDocuments } from "./Document/Document.saga";
import { watcherFetchEquipmentInventories } from "./EquipmentInventory/EquipmentInventory.saga";
import { watcherFetchAllEquipmentPlans } from "./EquipmentPlan/EquipmentPlan.saga";
import { watcherFetchEquipmentTransfer } from "./EquipmentTransfer/EquipmentTransfer.saga";
import { watcherFetchAllEquipmentUsage } from "./EquipmentUsage/EquipmentUsage.saga";
import { watcherFetchAllEquipmentUsagePlans } from "./EquipmentUsagePlan/EquipmentUsagePlan.saga";
import { watcherFetchAllExpense } from "./Expense/Expense.saga";
import { watcherFetchAllInvoice } from "./Invoice/Invoice.saga";
import { watcherFetchAllLabour } from "./Labour/Labour.saga";
import { watcherFetchAllLabourPlans } from "./LabourPlan/LabourPlan.saga";
import { watcherFetchAllLabourUsage } from "./LabourUsage/LabourUsage.saga";
import { watcherFetchMaterials } from "./Material/Material.saga";
import { watcherFetchMaterialInventoriesAnalysis } from "./MaterialInventory/MaterialInventory.saga";
import { watcherFetchAllMaterialPlans } from "./MaterialPlan/MaterialPlan.saga";
import { watcherFetchMaterialTransfer } from "./MaterialTransfer/MaterialTransfer.saga";
import { watcherFetchAllPayment } from "./Payment/Payment.saga";
import { watcherFetchAllPaymentRetention } from "./PaymentRetention/PaymentRetention.saga";
import {
  watcherFetchAllEmployeeAccommodationPlan,
  watcherFetchOneEmployeeAccommodationPlan,
} from "./EmployeeAccommodationPlan/EmployeeAccommodationPlan.saga";

import {
  watcherFetchAllPreProjects,
  watcherFetchAllProjects,
  watcherFetchOnePreProjects,
  watcherFetchOneProjects,
  watcherFetchAllListProjects,
} from "./Project/Project.saga";
import { watcherFetchAllProjectStaffs } from "./ProjectStaff/ProjectStaff.saga";
import { watcherFetchAllServices } from "./Service/Service.saga";
import {
  watcherFetchAllStaffs,
  watcherFetchOneStaffs,
  watcherFetchAllStaffDetails,
} from "./Staff/Staff.saga";
import { watcherFetchAllStaffPlans } from "./StaffPlan/StaffPlan.saga";
import {
  watcherFetchAllSubContracts,
  watcherFetchOneSubContracts,
} from "./SubContract/SubContract.saga";
import { watcherFetchAllSubContractPlans } from "./SubContractPlan/SubContractPlan.saga";
import { watcherFetchSupplies } from "./Supplier/Supplier.saga";
import {
  watcherFetchAllUnitBreakdownsPost,
  watcherFetchAllUnitBreakdownsPre,
} from "./UnitBreakdown/UnitBreakdown.saga";
import { watcherFetchAllUsage } from "./Usage/Usage.saga";
import {
  watcherFetchAllVariation,
  watcherFetchBoqVariation,
  watcherFetchPIDVariation,
} from "./Variation/Variation.saga";
import { watcherFetchAllManpowerOvertime } from "./ManpowerOvertime/ManpowerOvertime.saga";
import { watcherFetchAllSchedules } from "./Schedule/Schedule.saga";
import { watcherFetchAllEmployeeExtras } from "./EmployeeExtra/EmployeeExtra.saga";
import {
  watcherFetchAllUser,
  watcherFetchOneUser,
  watcherFetchLoggedInUser,
} from "./User/User.saga";
import { watcherFetchAllAttendances } from "./Attendance/Attendance.saga";
import { watcherFetchAllEmployeeRequests } from "./EmployeeRequest/EmployeeRequest.saga";
import {
  watcherFetchAllMaterialRequisitions,
  watcherFetchOneMaterialRequisitions,
  watcherFetchMaterialRequisitionItemStatus,
} from "./MaterialRequisition/MaterialRequisition.saga";
import {
  watcherFetchAllPurchaseRequisitions,
  watcherFetchOnePurchaseRequisitions,
} from "./PurchaseRequisition/PurchaseRequisition.saga";
import {
  watcherFetchAllPurchaseOrders,
  watcherFetchOnePurchaseOrders,
} from "./PurchaseOrder/PurchaseOrder.saga";
import { watcherFetchAllPurchaseBillings } from "./PurchaseBilling/PurchaseBilling.saga";
import { watcherFetchAllGoodReceived } from "./GoodReceived/GoodReceived.saga";
import { watcherFetchAllRenters } from "./Renter/Renter.saga";
import {
  watcherFetchAllGoodOut,
  watcherFetchGoodOutReport,
} from "./GoodOut/GoodOut.saga";
import { watcherFetchAllGoodReturn } from "./GoodReturn/GoodReturn.saga";
import { watcherFetchEmployeeHistory } from "./EmployeeHistory/EmployeeHistory.saga";
import { watcherFetchAllDocumentWorkOrders } from "./DocumentWorkOrder/DocumentWorkOrder.saga";
import { watcherFetchAllEquipmentWO } from "./EquipmentWO/EquipmentWO.saga";
import { watcherFetchAllPriceEscalations } from "./PriceEscalation/PriceEscalation.saga";
import { watcherFetchAllHRPolicy } from "./HRPolicy/HRPolicy.saga";
import { watcherFetchAllAbsences } from "./Absence/Absence.saga";
import { watcherFetchAllEmployeeDocuments } from "./EmployeeDocument/EmployeeDocument.saga";
import {
  watcherFetchAllLabourPayrolls,
  watcherFetchAllStaffPayrolls,
  watcherFetchAllPayrolls,
  watcherFetchAllPayrollReport,
  watcherFetchAllPayrollStaffHistory,
} from "./Payroll/Payroll.saga";
import { watcherFetchAllClients } from "./Client/Client.saga";
import {
  watcherFetchAllMeetings,
  watcherFetchOneMeetings,
} from "./Meeting/Meeting.saga";
import {
  watcherFetchAllTaskMeetings,
  watcherFetchOneTaskMeetings,
} from "./TaskMeeting/TaskMeeting.saga";
import { watcherFetchAllAllowances } from "./Allowance/Allowance.saga";
import { watcherFetchAllAllowancePolicy } from "./AllowancePolicy/AllowancePolicy.saga";
import {
  watcherFetchAllStandard,
  watcherFetchOneStandard,
} from "./Standard/Standard.saga";
import { watcherFetchAllLog, watcherFetchOneLog } from "./Log/Log.saga";
import {
  watcherFetchAllBoqStandard,
  watcherFetchOneBoqStandard,
} from "./BoqStandard/BoqStandard.saga";
import {
  watcherFetchAllResources,
  watcherFetchOneResources,
} from "./Resource/Resource.sage";

import {
  watcherFetchAllInspectionForm,
  watcherFetchOneInspectionForm,
} from "./InspectionForm/InspectionForm.saga";
import {
  watcherFetchAllInspection,
  watcherFetchOneInspection,
} from "./Inspection/Inspection.saga";
import { watcherFetchAllCastings } from "./Casting/Casting.saga";
import {
  watcherFetchAllMaterialRequestApprovals,
  watcherFetchOneMaterialRequestApprovals,
} from "./MaterialRequestApproval/MaterialRequestApproval.saga";
import {
  watcherFetchAllTestResult,
  watcherFetchOneTestResult,
} from "./TestResult/TestResult.saga";
import {
  watcherFetchAllTestRequest,
  watcherFetchOneTestRequest,
} from "./TestRequest/TestRequest.saga";
import {
  watcherFetchAllSubmittals,
  watcherFetchOneSubmittals,
} from "./Submittal/Submittal.saga";
import { watcherFetchAllSiteDiary } from "./SiteDiary/SiteDiary.saga";
import {
  watcherFetchAllWeekReport,
  watcherFetchOneWeekReport,
} from "./WeekReport/WeekReport.saga";
import {
  watcherFetchAllQueries,
  watcherFetchOneQueries,
} from "./Query/Query.saga";
import {
  watcherFetchAllMasterSchedules,
  watcherFetchOneMasterSchedules,
} from "./MasterSchedule/MasterSchedule.saga";
import { watcherFetchAllRFIs } from "./RFI/RFI.saga";
import { watcherFetchAllReviewForm } from "./ReviewForm/ReviewForm.saga";
import { watcherFetchAllEmployeeReview } from "./EmployeeReview/EmployeeReview.saga";
import { watcherFetchAllBonuses } from "./Bonus/Bonus.saga";

import {
  watcherFetchAllVacancies,
  watcherFetchVacanciesByAttributes,
  watcherFetchVacanciesByJobId,
} from "./Vacancy/Vacancy.saga";
import { watcherFetchAllApplications } from "./Application/Application.saga";
import { watcherFetchAllMedias } from "./Media/Media.saga";
import { watcherFetchAllSHEs } from "./SHE/SHE.saga";
import { watcherFetchAllSharedDocuments } from "./SharedDocument/SharedDocument.saga";

import { watcherFetchAllBankAccounts } from "./BankAccount/BankAccount.saga";
import {
  watcherFetchAllLetters,
  watcherFetchOneLetters,
} from "./Letter/Letter.saga";

import {
  watcherFetchAllAccountTransactions,
  watcherFetchAccountTransactionsAnalysis,
  watcherFetchAllAllAccountTransactions,
} from "./AccountTransaction/AccountTransaction.saga";
import { watcherFetchAllRentContracts } from "./RentContract/RentContract.saga";
import {
  watcherFetchAllRiskLogs,
  watcherFetchOneRiskLogs,
} from "./RiskLog/RiskLog.saga";
import {
  watcherFetchAllStaffWorks,
  watcherFetchOneStaffWorks,
} from "./StaffWork/StaffWork.saga";
import {
  watcherFetchAllReceivables,
  watcherFetchOneReceivables,
} from "./Receivable/Receivable.saga";

import { watcherFetchAllAttachments } from "./Attachment/Attachment.saga";
import {
  watcherFetchAllPaymentFiles,
  watcherFetchOnePaymentFiles,
} from "./PaymentFile/PaymentFile.saga";

import {
  watcherFetchAllPettyCashes,
  watcherFetchOnePettyCashes,
} from "./PettyCash/PettyCash.saga";

import {
  watcherFetchAllReplenishments,
  watcherFetchOneReplenishments,
} from "./Replenish/Replenishment.saga";

import {
  watcherFetchAllReplenishmentTransactions,
  watcherFetchOneReplenishmentTransactions,
} from "./ReplenishTransaction/ReplenishmentTransaction.saga";

import {
  watcherFetchAllReplenishmentRequests,
  watcherFetchOneReplenishmentRequests,
} from "./ReplenishRequest/ReplenishmentRequest.saga";

import {
  watcherFetchAllTestEvaluations,
  watcherFetchOneTestEvaluations,
} from "./TestEvaluation/TestEvaluation.saga";

import {
  watcherFetchAllVariationFiles,
  watcherFetchOneVariationFiles,
} from "./VariationFile/VariationFile.saga";
import {
  watcherFetchAllPriceEscalationFiles,
  watcherFetchOnePriceEscalationFiles,
} from "./PriceEscalationFile/PriceEscalationFile.saga";

import {
  watcherFetchAllCRVPayments,
  watcherFetchOneCRVPayments,
} from "./CRVPayment/CRVPayment.saga";

import {
  watcherFetchAllPostChecks,
  watcherFetchOnePostChecks,
} from "./PostCheck/PostCheck.saga";
import {
  watcherFetchAllSiteBooks,
  watcherFetchOneSiteBooks,
} from "./SiteBook/SiteBook.saga";
import {
  watcherFetchAllMonthlyReports,
  watcherFetchOneMonthlyReports,
  watcherFetchBoqMonthlyReports,
} from "./MonthlyReport/MonthlyReport.saga";
import { watcherFetchAllDailyReports } from "./DailyReport/DailyReport.saga";
import {
  watcherFetchAllMaterialUsages,
  watcherFetchOneMaterialUsages,
} from "./MaterialUsage/MaterialUsage.saga";
import { watcherFetchAllPayables } from "./Payable/Payable.saga";
import {
  watcherFetchAllMemos,
  watcherFetchCountMemos,
  watcherFetchOneMemos,
} from "./Memo/Memo.saga";
import { watcherFetchAllBuildings } from "./Building/Building.saga";
import { watcherFetchAllApartments } from "./Apartment/Apartment.saga";
import { watcherFetchAllApartmentExpenses } from "./ApartmentExpense/ApartmentExpense.saga";
import { watcherFetchAllContractSaless } from "./ContractSales/ContractSales.saga";
import { watcherFetchAllEstimatedBuildingCosts } from "./EstimatedBuildingCost/EstimatedBuildingCost.saga";
import {
  watcherFetchAllProformas,
  watcherFetchOneProformas,
} from "./Proforma/Proforma.saga";
import { watcherFetchAllCurrencies } from "./Currency/Currency.saga";
import { watcherFetchAllApartmentEstimateCosts } from "./ApartmentEstimateCost/ApartmentEstimateCost.saga";

import {
  watcherFetchAllProcurementPlans,
  watcherFetchOneProcurementPlans,
} from "./ProcurementPlan/ProcurementPlan.saga";

import {
  watcherFetchAllStores,
  watcherFetchOneStores,
} from "./Store/Store.saga";

import {
  watcherFetchAllProformaComparisons,
  watcherFetchOneProformaComparisons,
} from "./ProformaComparison/ProformaComparison.saga";
import {
  watcherFetchAllInventories,
  watcherFetchStockMovement,
} from "./Inventory/Inventory.saga";
import {
  watcherFetchAllContractAgreements,
  watcherFetchOneContractAgreements,
} from "./ContractAgreement/ContractAgreement.saga";
import {
  watcherFetchAllAreaProgresss,
  watcherFetchOneAreaProgresss,
} from "./AreaProgress/AreaProgress.saga";
import { watcherFetchAllBoqSummaries } from "./BoqSummary/BoqSummary.saga";

//CRM
import { watcherFetchAllLeads, watcherFetchOneLead } from "./Lead/Lead.saga";
import { watcherFetchAllContacts } from "./Contact/Contact.saga";
import { watcherFetchAllActivities } from "./Activity/Activity.saga";
import { watcherFetchAllWalkIns } from "./Lead/WalkIn/WalkIn.saga";
import {
  watcherFetchAllOpportunities,
  watcherFetchOneOpportunity,
} from "./CRM/Opportunity/Opportunity.saga";
import {
  watcherFetchAllOpportunityUpdates,
  watcherFetchOneOpportunityUpdate,
  watcherFetchOpportunityUpdates,
} from "./CRM/Opportunity/OpportunityUpdate/OpportunityUpdate.saga";
import {
  watcherFetchOneWeeklyPlan,
  watcherFetchAllWeeklyPlans,
} from "./CRM/WeeklyPlan/WeeklyPlan.saga";
import {
  watcherFetchOneSupervisorPlan,
  watcherFetchAllSupervisorPlans,
} from "./CRM/SupervisorPlan/SupervisorPlan.saga";
import {
  watcherFetchOneProjectSite,
  watcherFetchAllProjectSites,
} from "./CRM/ProjectSite/ProjectSite.saga";

import {
  watcherFetchOneCRMDailyReport,
  watcherFetchAllCRMDailyReports,
} from "./CRM/DailyReport/DailyReport.saga";
// Apartment Building
import {
  watcherFetchOneApartmentBuilding,
  watcherFetchAllApartmentBuildings,
} from "./ApartmentBuilding/ApartmentBuilding/ApartmentBuilding.saga";
import {
  watcherFetchOneApartmentUnit,
  watcherFetchAllApartmentUnits,
} from "./ApartmentBuilding/ApartmentUnit/ApartmentUnit.saga";
import {
  watcherFetchAllAccountCharts,
  watcherFetchOneAccountCharts,
} from "./AccountChart/AccountChart.saga";
import {
  watcherFetchAllAccountChartBalances,
  watcherFetchOneAccountChartBalances,
} from "./AccountChartBalance/AccountChartBalance.saga";

import { watcherFetchAllMasterScheduleLists } from "./MasterScheduleList/MasterScheduleList.saga";
import { watcherFetchAllReciepts } from "./Reciept/Reciept.saga";
import { watcherFetchExchangeRates } from "./ExchangeRate/ExchangeRate.saga";
import { watcherFetchAllQuotations } from "./Quotation/Quotation.saga";

import {
  watcherFetchAllTeams,
  watcherFetchPagedTeams,
  watcherFetchAllTeamWithMembers,
} from "./Team/Team.saga";
import {
  watcherFetchAllTeamMembers,
  watcherFetchPagedTeamMembers,
} from "./TeamMember/TeamMember.saga";

import {
  watcherFetchAllProjectContracts,
  watcherFetchAllAllProjectContracts,
  watcherFetchOneProjectContracts,
} from "./ProjectContract/ProjectContract.saga";
import { watcherFetchAllUserControls } from "./UserControl/UserControl.saga";

import {
  watcherFetchAllDailyTasks,
  watcherFetchOneDailyTasks,
} from "./Task/DailyTask/DailyTask.saga";
import {
  watcherFetchAllIOLetters,
  watcherFetchOneIOLetters,
} from "./IOLetter/IOLetter.saga";

import {
  watcherFetchAllSalesReports,
  watcherFetchOneSalesReports,
} from "./SalesReport/SalesReport.saga";
import {
  watcherFetchAllAutomaticMessages,
  watcherFetchOneAutomaticMessages,
} from "./Task/AutomaticMessage/AutomaticMessage.saga";
import {
  watcherFetchAllNewsAndMotivations,
  watcherFetchOneNewsAndMotivations,
} from "./Task/NewsAndMotivation/NewsAndMotivation.saga";
import {
  watcherFetchAllPartners,
  watcherFetchOnePartners,
} from "./Task/Partner/Partner.saga";
import {
  watcherFetchAllPartnerMessages,
  watcherFetchOnePartnerMessages,
} from "./Task/PartnerMessage/PartnerMessage.saga";
import {
  watcherFetchAllSMSMessages,
  watcherFetchOneSMSMessages,
} from "./Task/SMSMessage/SMSMessage.saga";

import {
  watcherFetchAllWeeklySalesPlans,
  watcherFetchOneWeeklySalesPlans,
} from "./WeeklySalesPlan/WeeklySalesPlan.saga";

import {
  watcherFetchAllBoqRevises,
  watcherFetchOneBoqRevises,
  watcherFetchPagedBoqRevises,
} from "./BoqRevise/BoqRevise.saga";

import {
  watcherFetchAllBoqSubCategorys,
  watcherFetchOneBoqSubCategorys,
  watcherFetchPagedBoqSubCategorys,
} from "./BoqSubCategory/BoqSubCategory.saga";
import {
  watcherFetchAllBoqCategorys,
  watcherFetchOneBoqCategorys,
  watcherFetchPagedBoqCategorys,
} from "./BoqCategory/BoqCategory.saga";
import {
  watcherFetchAllMonthlyReportV2s,
  watcherFetchOneMonthlyReportV2s,
  watcherFetchPagedMonthlyReportV2s,
  watcherFetchMonthlyReportV2s,
} from "./MonthlyReportV2/MonthlyReportV2.saga";

import {
  watcherFetchAllMaterialBudgets,
  watcherFetchOneMaterialBudgets,
} from "./MaterialBudget/MaterialBudget.saga";

import {
  watcherFetchAllSubContractBudgets,
  watcherFetchOneSubContractBudgets,
} from "./SubContractBudget/SubContractBudget.saga";

import {
  watcherFetchAllLabourBudgets,
  watcherFetchOneLabourBudgets,
} from "./LabourBudget/LabourBudget.saga";

import {
  watcherFetchAllMiscellaneousBudgets,
  watcherFetchOneMiscellaneousBudgets,
} from "./MiscellaneousBudget/MiscellaneousBudget.saga";

import {
  watcherFetchAllTasks,
  watcherFetchAllFormTasks,
  watcherFetchTaskReport,
  watcherFetchOneTasks,
  watcherFetchAllProjectReport,
} from "./Tasks/Task.saga";
import {
  watcherFetchAllTaskCategorys,
  watcherFetchAllFormTaskCategorys,
  watcherFetchAllDetailedTaskCategorys,
  watcherFetchOneTaskCategorys,
} from "./TaskCategory/TaskCategory.saga";

import {
  watcherFetchAllTaskSchedules,
  watcherFetchOneTaskSchedules,
} from "./TaskSchedule/TaskSchedule.saga";

import {
  watcherFetchAllTaskDepartments,
  watcherFetchOneTaskDepartments,
} from "./TaskDepartment/TaskDepartment.saga";

import {
  watcherFetchAllPaymentRequests,
  watcherFetchOnePaymentRequests,
} from "./PaymentRequest/PaymentRequest.saga";

import {
  watcherFetchAllVoucherAuthorizers,
  watcherFetchOneVoucherAuthorizers,
} from "./VoucherAuthorizer/VoucherAuthorizer.saga";

import {
  watcherFetchPagedMaterialMajorCategorys,
  watcherFetchAllMaterialMajorCategorys,
  watcherFetchAllFormMaterialMajorCategorys,
  watcherFetchOneMaterialMajorCategorys,
} from "./MaterialMajorCategory/MaterialMajorCategory.saga";

import {
  watcherFetchPagedMaterialSubCategorys,
  watcherFetchAllMaterialSubCategorys,
  watcherFetchAllFormMaterialSubCategorys,
} from "./MaterialSubCategory/MaterialSubCategory.saga";

import {
  watcherFetchAllInterGoodTransferOuts,
  watcherFetchOneInterGoodTransferOuts,
  watcherFetchReportInterGoodTransferOuts,
} from "./InterGoodTransferOut/InterGoodTransferOut.saga";

import {
  watcherFetchAllGoodIns,
  watcherFetchOneGoodIns,
  watcherFetchPagedGoodIns,
  watcherFetchReportGoodIns,
} from "./GoodIn/GoodIn.saga";

export default function* RootSaga() {
  yield all([
    /**
     * Project Sagas
     */
    call(watcherFetchAllProjects),
    call(watcherFetchOneProjects),
    call(watcherFetchAllListProjects),
    call(watcherFetchAllContractors),
    call(watcherFetchAllConsultants),
    call(watcherFetchAllBoqs),
    call(watcherFetchOneBoqs),
    call(watcherFetchAllSubContractPlans),
    call(watcherFetchMaterials),
    call(watcherFetchSupplies),
    call(watcherFetchMaterialTransfer),
    call(watcherFetchAllUsage),
    call(watcherFetchEquipmentInventories),
    call(watcherFetchEquipmentTransfer),
    call(watcherFetchAllEquipmentUsage),
    call(watcherFetchAllLabourUsage),
    call(watcherFetchAllLabour),
    call(watcherFetchAllEquipmentPlans),
    call(watcherFetchAllEquipmentUsagePlans),
    call(watcherFetchAllMaterialPlans),
    call(watcherFetchAllLabourPlans),
    call(watcherFetchAllDepartments),
    call(watcherFetchAllStaffs),
    call(watcherFetchAllStaffDetails),
    call(watcherFetchAllStaffPlans),
    call(watcherFetchAllAccounts),
    call(watcherFetchAllCustomers),
    call(watcherFetchAllServices),
    call(watcherFetchAllUnitBreakdownsPost),
    call(watcherFetchAllUnitBreakdownsPre),
    call(watcherFetchAllVariation),
    call(watcherFetchBoqVariation),
    call(watcherFetchAllSubContracts),
    call(watcherFetchOneSubContracts),
    call(watcherFetchAllExpense),
    call(watcherFetchAllPayment),
    call(watcherFetchAllInvoice),
    call(watcherFetchAllPaymentRetention),
    call(watcherFetchOneAccounts),
    call(watcherFetchAllDocuments),
    call(watcherFetchAllProjectStaffs),
    call(watcherFetchAllManpowerOvertime),
    call(watcherFetchAllStaffPayrolls),
    call(watcherFetchAllLabourPayrolls),
    call(watcherFetchAllSchedules),
    call(watcherFetchAllPayrolls),
    call(watcherFetchAllEmployeeExtras),
    call(watcherFetchAllStaffPayrolls),
    call(watcherFetchOneUser),
    call(watcherFetchAllAttendances),
    call(watcherFetchAllEmployeeRequests),
    call(watcherFetchAllMaterialRequisitions),
    call(watcherFetchAllPurchaseRequisitions),
    call(watcherFetchAllPurchaseOrders),
    call(watcherFetchOnePurchaseOrders),
    call(watcherFetchAllPurchaseBillings),
    call(watcherFetchAllGoodReceived),
    call(watcherFetchAllRenters),
    call(watcherFetchAllGoodOut),
    call(watcherFetchGoodOutReport),
    call(watcherFetchAllGoodReturn),
    call(watcherFetchEmployeeHistory),
    call(watcherFetchAllDocumentWorkOrders),
    call(watcherFetchAllEquipmentWO),
    call(watcherFetchAllPriceEscalations),
    call(watcherFetchAllHRPolicy),
    call(watcherFetchAllAbsences),
    call(watcherFetchOneStaffs),
    call(watcherFetchAllEmployeeDocuments),
    call(watcherFetchAllClients),
    call(watcherFetchAllAllowances),
    call(watcherFetchAllAllowancePolicy),
    call(watcherFetchAllPreProjects),
    call(watcherFetchOnePreProjects),
    call(watcherFetchAllStandard),
    call(watcherFetchOneStandard),
    call(watcherFetchAllUser),
    call(watcherFetchOneUser),
    call(watcherFetchAllLog),
    call(watcherFetchOneLog),
    call(watcherFetchAllMeetings),
    call(watcherFetchOneMeetings),
    call(watcherFetchAllTaskMeetings),
    call(watcherFetchOneTaskMeetings),
    call(watcherFetchAllEmployeeAccommodationPlan),
    call(watcherFetchOneEmployeeAccommodationPlan),
    call(watcherFetchAllBoqStandard),
    call(watcherFetchOneBoqStandard),
    call(watcherFetchAllResources),
    call(watcherFetchOneResources),
    call(watcherFetchPIDVariation),
    call(watcherFetchAllInspectionForm),
    call(watcherFetchOneInspectionForm),
    call(watcherFetchAllInspection),
    call(watcherFetchOneInspection),
    call(watcherFetchAllCastings),
    call(watcherFetchAllTestResult),
    call(watcherFetchOneTestResult),
    call(watcherFetchAllTestRequest),
    call(watcherFetchOneTestRequest),
    call(watcherFetchAllSubmittals),
    call(watcherFetchOneSubmittals),
    call(watcherFetchAllMaterialRequestApprovals),
    call(watcherFetchOneMaterialRequestApprovals),
    call(watcherFetchAllSiteDiary),
    call(watcherFetchAllWeekReport),
    call(watcherFetchOneWeekReport),
    call(watcherFetchAllQueries),
    call(watcherFetchOneQueries),
    call(watcherFetchAllMasterSchedules),
    call(watcherFetchOneMasterSchedules),
    call(watcherFetchAllRFIs),
    call(watcherFetchAllReviewForm),
    call(watcherFetchAllEmployeeReview),
    call(watcherFetchAllBonuses),
    call(watcherFetchAllVacancies),
    call(watcherFetchAllApplications),
    call(watcherFetchVacanciesByAttributes),
    call(watcherFetchVacanciesByJobId),
    call(watcherFetchAllPayrollReport),
    call(watcherFetchAllPayrollStaffHistory),
    call(watcherFetchAllMedias),
    call(watcherFetchAllSHEs),
    call(watcherFetchAllSharedDocuments),
    call(watcherFetchAllBankAccounts),
    call(watcherFetchAllLetters),
    call(watcherFetchOneLetters),
    call(watcherFetchAllAccountTransactions),
    call(watcherFetchAccountTransactionsAnalysis),
    call(watcherFetchAllAllAccountTransactions),
    call(watcherFetchAllRentContracts),
    call(watcherFetchAllRiskLogs),
    call(watcherFetchOneRiskLogs),
    call(watcherFetchAllStaffWorks),
    call(watcherFetchOneStaffWorks),
    call(watcherFetchAllReceivables),
    call(watcherFetchOneReceivables),
    call(watcherFetchAllAttachments),
    call(watcherFetchMaterialInventoriesAnalysis),
    call(watcherFetchAllPettyCashes),
    call(watcherFetchOnePettyCashes),
    call(watcherFetchAllReplenishments),
    call(watcherFetchOneReplenishments),
    call(watcherFetchAllReplenishmentTransactions),
    call(watcherFetchOneReplenishmentTransactions),
    call(watcherFetchAllReplenishmentRequests),
    call(watcherFetchOneReplenishmentRequests),
    call(watcherFetchAllTestEvaluations),
    call(watcherFetchOneTestEvaluations),
    call(watcherFetchAllPaymentFiles),
    call(watcherFetchOnePaymentFiles),
    call(watcherFetchAllVariationFiles),
    call(watcherFetchOneVariationFiles),
    call(watcherFetchAllPriceEscalationFiles),
    call(watcherFetchOnePriceEscalationFiles),
    call(watcherFetchAllCRVPayments),
    call(watcherFetchOneCRVPayments),
    call(watcherFetchAllPostChecks),
    call(watcherFetchOnePostChecks),
    call(watcherFetchAllSiteBooks),
    call(watcherFetchOneSiteBooks),
    call(watcherFetchAllMonthlyReports),
    call(watcherFetchOneMonthlyReports),
    call(watcherFetchAllDailyReports),
    call(watcherFetchAllMaterialUsages),
    call(watcherFetchOneMaterialUsages),
    call(watcherFetchAllPayables),
    call(watcherFetchAllMemos),
    call(watcherFetchOneMemos),
    call(watcherFetchCountMemos),
    call(watcherFetchAllBuildings),
    call(watcherFetchAllApartments),
    call(watcherFetchAllApartmentExpenses),
    call(watcherFetchAllContractSaless),
    call(watcherFetchAllEstimatedBuildingCosts),
    call(watcherFetchOneMaterialRequisitions),
    call(watcherFetchMaterialRequisitionItemStatus),
    call(watcherFetchOnePurchaseRequisitions),
    call(watcherFetchAllProformas),
    call(watcherFetchOneProformas),
    call(watcherFetchLoggedInUser),
    call(watcherFetchAllCurrencies),
    call(watcherFetchAllApartmentEstimateCosts),
    call(watcherFetchAllProcurementPlans),
    call(watcherFetchOneProcurementPlans),
    call(watcherFetchAllStores),
    call(watcherFetchOneStores),
    call(watcherFetchAllProformaComparisons),
    call(watcherFetchOneProformaComparisons),
    call(watcherFetchAllInventories),
    call(watcherFetchStockMovement),
    call(watcherFetchAllContractAgreements),
    call(watcherFetchOneContractAgreements),
    call(watcherFetchAllAreaProgresss),
    call(watcherFetchOneAreaProgresss),
    call(watcherFetchBoqTotal),
    call(watcherFetchSheetNames),
    call(watcherFetchAllBoqSummaries),

    call(watcherFetchAllAccountCharts),
    call(watcherFetchOneAccountCharts),
    call(watcherFetchAllAccountChartBalances),
    call(watcherFetchOneAccountChartBalances),

    //CRM
    call(watcherFetchAllLeads),
    call(watcherFetchOneLead),

    call(watcherFetchAllContacts),
    call(watcherFetchAllActivities),
    call(watcherFetchAllWalkIns),

    call(watcherFetchAllOpportunities),
    call(watcherFetchOneOpportunity),

    call(watcherFetchAllOpportunityUpdates),
    call(watcherFetchOneOpportunityUpdate),
    call(watcherFetchOpportunityUpdates),

    call(watcherFetchAllWeeklyPlans),
    call(watcherFetchOneWeeklyPlan),

    call(watcherFetchAllSupervisorPlans),
    call(watcherFetchOneSupervisorPlan),

    call(watcherFetchAllProjectSites),
    call(watcherFetchOneProjectSite),

    call(watcherFetchAllCRMDailyReports),
    call(watcherFetchOneCRMDailyReport),

    call(watcherFetchAllMasterScheduleLists),

    call(watcherFetchAllTeams),
    call(watcherFetchPagedTeams),
    call(watcherFetchAllTeamWithMembers),
    call(watcherFetchAllTeamMembers),
    call(watcherFetchPagedTeamMembers),

    // Apartment Buildings
    call(watcherFetchAllApartmentBuildings),
    call(watcherFetchOneApartmentBuilding),
    call(watcherFetchAllApartmentUnits),
    call(watcherFetchOneApartmentUnit),
    call(watcherFetchAllLeads),
    call(watcherFetchAllReciepts),
    call(watcherFetchExchangeRates),
    call(watcherFetchAllQuotations),
    call(watcherFetchAllProjectContracts),
    call(watcherFetchAllAllProjectContracts),
    call(watcherFetchOneProjectContracts),
    call(watcherFetchAllUserControls),

    // Task
    call(watcherFetchAllDailyTasks),
    call(watcherFetchOneDailyTasks),

    // IOLetter
    call(watcherFetchAllIOLetters),
    call(watcherFetchOneIOLetters),

    call(watcherFetchAllSalesReports),
    call(watcherFetchOneSalesReports),
    call(watcherFetchAllAutomaticMessages),
    call(watcherFetchOneAutomaticMessages),
    call(watcherFetchAllNewsAndMotivations),
    call(watcherFetchOneNewsAndMotivations),
    call(watcherFetchAllPartners),
    call(watcherFetchOnePartners),
    call(watcherFetchAllPartnerMessages),
    call(watcherFetchOnePartnerMessages),
    call(watcherFetchAllSMSMessages),
    call(watcherFetchOneSMSMessages),

    call(watcherFetchAllWeeklySalesPlans),
    call(watcherFetchOneWeeklySalesPlans),

    call(watcherFetchAllBoqRevises),
    call(watcherFetchOneBoqRevises),
    call(watcherFetchPagedBoqRevises),
    call(watcherFetchAllBoqSubCategorys),
    call(watcherFetchOneBoqSubCategorys),
    call(watcherFetchPagedBoqSubCategorys),
    call(watcherFetchAllBoqCategorys),
    call(watcherFetchOneBoqCategorys),
    call(watcherFetchPagedBoqCategorys),
    call(watcherFetchBoqMonthlyReports),
    call(watcherFetchAllMonthlyReportV2s),
    call(watcherFetchOneMonthlyReportV2s),
    call(watcherFetchPagedMonthlyReportV2s),
    call(watcherFetchMonthlyReportV2s),

    call(watcherFetchAllMaterialBudgets),
    call(watcherFetchOneMaterialBudgets),

    call(watcherFetchAllSubContractBudgets),
    call(watcherFetchOneSubContractBudgets),

    call(watcherFetchAllLabourBudgets),
    call(watcherFetchOneLabourBudgets),

    call(watcherFetchAllMiscellaneousBudgets),
    call(watcherFetchOneMiscellaneousBudgets),

    call(watcherFetchAllTasks),
    call(watcherFetchAllFormTasks),
    call(watcherFetchTaskReport),
    call(watcherFetchOneTasks),
    call(watcherFetchAllProjectReport),

    call(watcherFetchAllTaskCategorys),
    call(watcherFetchAllFormTaskCategorys),
    call(watcherFetchAllDetailedTaskCategorys),
    call(watcherFetchOneTaskCategorys),

    call(watcherFetchAllTaskSchedules),
    call(watcherFetchOneTaskSchedules),

    call(watcherFetchAllTaskDepartments),
    call(watcherFetchOneTaskDepartments),

    call(watcherFetchAllPaymentRequests),
    call(watcherFetchOnePaymentRequests),

    call(watcherFetchAllVoucherAuthorizers),
    call(watcherFetchOneVoucherAuthorizers),

    call(watcherFetchPagedMaterialMajorCategorys),
    call(watcherFetchPagedMaterialSubCategorys),
    call(watcherFetchAllMaterialMajorCategorys),
    call(watcherFetchAllFormMaterialMajorCategorys),
    call(watcherFetchOneMaterialMajorCategorys),
    call(watcherFetchAllMaterialSubCategorys),
    call(watcherFetchAllFormMaterialSubCategorys),

    call(watcherFetchAllInterGoodTransferOuts),
    call(watcherFetchOneInterGoodTransferOuts),
    call(watcherFetchReportInterGoodTransferOuts),

    call(watcherFetchAllGoodIns),
    call(watcherFetchOneGoodIns),
    call(watcherFetchPagedGoodIns),
    call(watcherFetchReportGoodIns),
  ]);
}
