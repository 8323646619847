import { TaskDepartment } from "../TaskDepartment/TaskDepartment.type";
import { ApiCallState, PagedData } from "../Utils";

export type TaskSchedule = {
  id?: number;
  date: string;
  description: string;
  task_department_id: number;
  task_department:TaskDepartment;
};

export type TaskScheduleStateTypes = {
  fetchAll: ApiCallState<TaskSchedule[]>;
  fetchOne: ApiCallState<TaskSchedule| {}>;
  fetchPaged:ApiCallState<PagedData<TaskSchedule[]>>
};

export const TaskScheduleActionTypes = {
  FETCH_ALL_TASK_SCHEDULE: "FETCH_ALL_TASK_SCHEDULE",
  FETCH_ALL_TASK_SCHEDULE_RESET: "FETCH_ALL_TASK_SCHEDULE_RESET",
  FETCH_ALL_TASK_SCHEDULE_FAILURE: "FETCH_ALL_TASK_SCHEDULE_FAILURE",
  FETCH_ALL_TASK_SCHEDULE_SUCCESS: "FETCH_ALL_TASK_SCHEDULE_SUCCESS",

  FETCH_PAGED_TASK_SCHEDULE: "FETCH_PAGED_TASK_SCHEDULE",
  FETCH_PAGED_TASK_SCHEDULE_RESET: "FETCH_PAGED_TASK_SCHEDULE_RESET",
  FETCH_PAGED_TASK_SCHEDULE_FAILURE: "FETCH_PAGED_TASK_SCHEDULE_FAILURE",
  FETCH_PAGED_TASK_SCHEDULE_SUCCESS: "FETCH_PAGED_TASK_SCHEDULE_SUCCESS",

  FETCH_ONE_TASK_SCHEDULE: "FETCH_ONE_TASK_SCHEDULE",
  FETCH_ONE_TASK_SCHEDULE_RESET: "FETCH_ONE_TASK_SCHEDULE_RESET",
  FETCH_ONE_TASK_SCHEDULE_FAILURE: "FETCH_ONE_TASK_SCHEDULE_FAILURE",
  FETCH_ONE_TASK_SCHEDULE_SUCCESS: "FETCH_ONE_TASK_SCHEDULE_SUCCESS",
};
