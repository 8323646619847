import { Document } from "../Document/Document.type";
import { Material } from "../Material/Material.type";
import { Supplier } from "../Supplier/Supplier.type";
import { User } from "../User/User.type";
import { ApiCallState } from "../Utils";

export type Proforma = {
  id: number;
  purchaser: string;
  date: string;
  purchase_requisition_id: number;
  approved_by: number;
  checked_by: number;
  authorized_by: number;
  approved: boolean;
  authorized: boolean;
  proforma_items: ProformaItem[];
  document?: Document;
  checked: boolean;
  end_date: string;
  user_id: number;
  proforma_statuses: ProformaStatus[];
  proforma_prepared_by: User;
  proforma_checked_by: User;
  proforma_approved_by: User;
};

export type ProformaStatus = {
  proforma_id: number;
  type: "View" | "Check" | "Approve";
  status: number;
  assigned_by: number;
  user_id: number;
  id: number;
};

export type ProformaItem = {
  id: number;
  key?: number;
  material_id: number;
  supplier_id: number;
  supplier?: Supplier;
  quantity: number;
  unit_price: number;
  amount?: number;
  specification?: string;
  tax?: number;
  remark?: string;
  status: string;
  winning_reason: string;
  material?: Material;
  proforma?: Proforma;
  type: "Service" | "Good Purchase";
};

export type ProformaStateTypes = {
  fetchAll: ApiCallState<Proforma[]>;
  fetchOne: ApiCallState<Proforma | {}>;
};

export const ProformaActionTypes = {
  FETCH_ALL_PROFORMA: "FETCH_ALL_PROFORMA",
  FETCH_ALL_PROFORMA_RESET: "FETCH_ALL_PROFORMA_RESET",
  FETCH_ALL_PROFORMA_FAILURE: "FETCH_ALL_PROFORMA_FAILURE",
  FETCH_ALL_PROFORMA_SUCCESS: "FETCH_ALL_PROFORMA_SUCCESS",

  FETCH_ONE_PROFORMA: "FETCH_ONE_PROFORMA",
  FETCH_ONE_PROFORMA_RESET: "FETCH_ONE_PROFORMA_RESET",
  FETCH_ONE_PROFORMA_FAILURE: "FETCH_ONE_PROFORMA_FAILURE",
  FETCH_ONE_PROFORMA_SUCCESS: "FETCH_ONE_PROFORMA_SUCCESS",
};
